
import { GiphyFetch } from '@giphy/js-fetch-api';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import image1 from "assets/images/products/product-details-1.jpg";
import MDAlert from "components/MDAlert";
import MDAvatar from "components/MDAvatar";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { setOpenConfigurator, useMaterialUIController, setCustomConfig, setOrderId } from "context";
import Configurator from "examples/Configurator";
import Footer from "examples/Footer";
// Material Dashboard 2 PRO React examples
import DefaultItem from "examples/Items/DefaultItem";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Rating from '@mui/material/Rating';
import moment from "moment";


import DefaultCell from "layouts/dashboards/sales/components/DefaultCell";
import MDBadge from "components/MDBadge";

import Firebase from "firebase";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";
import * as React from 'react';
import { useEffect, useState } from "react";

import ImgsViewer from "react-images-viewer";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import "core-js/actual/array/group-by";
const SMBase64 = require('smbase64')
let base64 = new SMBase64()
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";
import DataTable from "examples/Tables/DataTable";
const colorArray = [
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "error",
];
const iconArray = [
  "notifications",
  "inventory_2",
  "shopping_cart",
  "payment",
  "vpn_key",
  "inbox",
  "campaign",
  "archive",
  "sports_esports",
]
// Data
// import dataTableData2 from "layouts/applications/data-tables/data/dataTableData";

import dataTableData from "layouts/dashboards/sales/data/dataTableData";

// Data
import timelineData from "layouts/pages/projects/timeline/data/timelineData";

const firebaseConfig = {
  apiKey: "AIzaSyDSaYuHlpFznfk_nhTcitcoGG-8QUGK1ec",
  authDomain: "onroad3-e593b.firebaseapp.com",
  databaseURL: "https://onroad3-e593b-default-rtdb.firebaseio.com",
  projectId: "onroad3-e593b",
  storageBucket: "onroad3-e593b.appspot.com",
  messagingSenderId: "587064425563",
}
Firebase.initializeApp(firebaseConfig);
const gf = new GiphyFetch('d6r2Pnp7hZLTR6DpOxLDr4fS1H15BBgC')

function Home() {

  let trackID, pathName, phoneNumber;
  pathName = window?.location?.pathname
  if(pathName == '/' || !pathName){
  window?.location?.replace('https://onroad.app');
  }else{
    trackID = pathName.substring(1)
    phoneNumber = `+${base64.toNumber(trackID)}`
    if(!trackID) {
      window?.location?.replace('https://onroad.app');
    }
  }
  const today = moment().format("DD-MM-YYYY").toString();

  const [singnedIn, setSignedIn] = useState(false);
  const [invalidTrackID, setInvalidTrackID] = useState(false);
  const [deliveryStatus, setDeliveryStatus] = useState(false);
  const [deliveryProof, setDeliveryProof] = useState();
  const [trackingDetails, setTrackingDetails] = useState(null);
  const [trackViewConfig, setTrackViewConfig] = useState(null);
  const google = window.google;
  const directionsService = new google.maps.DirectionsService();

  const [giphyEmbedURL, setGiphyEmbedURL] = React.useState();  
  const [controller, dispatch] = useMaterialUIController();

//   const [newCardForm, setNewCardForm] = React.useState(false);
  const [deliveryReviewStars, setDeliveryReviewStars] = React.useState(5);
  const [tipamount, setTipamount] = React.useState();
  const [deliveryReviewNote, setDeliveryReviewNote] = React.useState("Thank you very much for the service");
  const labels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
  };
  const [hover, setHover] = React.useState(-1);
  const [recorded, setRecorded] = React.useState(false);

  const mapRef = React.useRef(null);


  const [imgsViewer, setImgsViewer] = React.useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = React.useState(0);
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    orderId
  } = controller;

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);
  const handleConfiguratorOpen = () => {
    setOpenConfigurator(dispatch, !openConfigurator);
  }
  const [menuPreviewImages, setMenuPreviewImages] = React.useState();

  const [deliveryGuyLocation, setDeliveryGuyLocation] = React.useState(null);
  const [destinationLocation, setDestinationLocation] = React.useState(null);
  const [isCustomerAdjustedLocation, setIsCustomerAdjustedLocation] = React.useState(null);
  const [locationLiveTracking, setLocationLiveTracking] = React.useState(null);
  const [markerRef, setMarkerRef] = React.useState(null);
  const [chatMessages, setChatMessages] = React.useState(null);
  const [remainingTime, setRemainingTime] = React.useState(0);
  const [remainingDistance, setRemainingDistance] = React.useState(0);
  const [bottomTabValue, setBottomTabValue] = React.useState(0);
  const [userTimelineData, setUserTimelineData] = React.useState();
  const [userRealtimeActivtyData, setUserRealtimeActivtyData] = React.useState([]);
  const mapStyles =  [
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#d6e2e6",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#cfd4d5",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#7492a8",
        },
      ],
    },
    {
      featureType: "administrative.neighborhood",
      elementType: "labels.text.fill",
      stylers: [
        {
          lightness: 25,
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#dde2e3",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#cfd4d5",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#dde2e3",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#7492a8",
        },
      ],
    },
    {
      featureType: "landscape.natural.terrain",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#dde2e3",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.icon",
      stylers: [
        {
          saturation: -100,
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#588ca4",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#a9de83",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#bae6a1",
        },
      ],
    },
    {
      featureType: "poi.sports_complex",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#c6e8b3",
        },
      ],
    },
    {
      featureType: "poi.sports_complex",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#bae6a1",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          saturation: -45,
        },
        {
          lightness: 10,
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#41626b",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#c1d1d6",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#a6b5bb",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#9fb6bd",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [
        {
          saturation: -70,
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#b4cbd4",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#588ca4",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#008cb5",
        },
      ],
    },
    {
      featureType: "transit.station.airport",
      elementType: "geometry.fill",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: -5,
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#a6cbe3",
        },
      ],
    },
  ]
  const pinMarkerSVG =
  "M10 3.5C10 4.70948 9.14112 5.71836 8 5.94999V13.5C8 13.7761 7.77614 14 7.5 14C7.22386 14 7 13.7761 7 13.5V5.94999C5.85888 5.71836 5 4.70948 5 3.5C5 2.11929 6.11929 1 7.5 1C8.88071 1 10 2.11929 10 3.5Z";
  const squareMarkerSVG =
  "M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z";

  useEffect(() => {
      if(singnedIn){
        return;
      }
      Firebase.auth().signInAnonymously()
      .then(() => {
        setSignedIn(true)
      })
      .catch((error) => {
        setSignedIn(false)
        var errorCode = error.code;
        var errorMessage = error.message;
      });
  }, []);

  useEffect(() => {
    if(trackID){
      setOrderId(dispatch, trackID)
    }
  }, [trackID]);
  

  // valid/invalid
 
  useEffect(async () => {
    if(singnedIn){

      const isValid = await Firebase.database().ref(`/trackhistory/${phoneNumber}/${today}/`).once('value')
        // const test = "trackhistory/+18622878740/13-11-2022/"
      if(!isValid.val()){
        setInvalidTrackID(true)
      }
      // Stop listening for updates when no longer required
    }
  }, [singnedIn]);


   // config data


  useEffect(() => {
    if(singnedIn){
      const onChildAdd = Firebase.database()
      .ref(`/trackconfig/teamtrack/${phoneNumber}/${today}`)
      .once('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setTrackViewConfig(data)
        } 
      });
    return () => Firebase.database().ref(`/trackconfig/teamtrack/${phoneNumber}/${today}`).off('value', onChildAdd);
    }

  }, [singnedIn]);


  // livetrack data

    const createCenterControl = (map) => {
      const controlButton = document.createElement("button");
    
      // Set CSS for the control.
      controlButton.style.backgroundColor = "#fff";
      controlButton.style.border = "2px solid #fff";
      controlButton.style.borderRadius = "3px";
      controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlButton.style.color = "rgb(25,25,25)";
      controlButton.style.cursor = "pointer";
      controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
      controlButton.style.fontSize = "14px";
      controlButton.style.lineHeight = "38px";
      controlButton.style.margin = "8px 0 22px 8px";
      controlButton.style.padding = "0 5px";
      controlButton.style.textAlign = "center";
    
      controlButton.textContent = `${moment().format("DD-MM-YYYY")}`;
      controlButton.title = "Change the date";
      controlButton.type = "button";
    
      // Setup the click event listeners: simply set the map to Chicago.
      controlButton.addEventListener("click", () => {
        
      });
    
      return controlButton;
    }
    
    React.useEffect(() => {
      if (phoneNumber) {
        const onChildAdd = Firebase.database()
          .ref(`/userlivelocationtracking/${phoneNumber}`)
          .on('value', snapshot => {
            const data = snapshot.val()
            if (data && data.latitude && data.longitude) {
              setLocationLiveTracking({
                lat: parseFloat(data.latitude),
                lng: parseFloat(data.longitude)
              })
            }
          });
        // Stop listening for updates when no longer required
        return () => Firebase.database().ref(`/userlivelocationtracking/${phoneNumber}`).off('value', onChildAdd);
      }
    }, []);

    React.useEffect(() => {
      if (locationLiveTracking && markerRef) {
        setTimeout(() => {
          markerRef.setPosition({
            lat: locationLiveTracking.lat,
            lng: locationLiveTracking.lng
          })
          // mapRef.panTo({lat: deliveryGuyLocationLiveTracking.lat,lng: deliveryGuyLocationLiveTracking.lng});
          // mapRef.setZoom(11);
          // animatingCamera(position.coords.latitude, position.coords.longitude, position.coords.heading);
          // getPointOnPath([position.coords.latitude, position.coords.longitude])
  
          // TODO 
          // mapRef.setHeading(45);
        }, 1000)
      }
    }, [locationLiveTracking])

  React.useEffect(() => {
    let google = window.google;
    let map = mapRef.current;
    let lat = "37.7799273"
    let lng = "-121.9780153";
    const myLatlng = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      zoom: 8,
      center: myLatlng,
      mapTypeControl: false,
      // mapTypeId: "satellite",
      styles: mapStyles,
    }

    map = new google.maps.Map(map, mapOptions);
    
    const centerControlDiv = document.createElement("div");
    // Create the control.
    const centerControl = createCenterControl(map);
    // Append the control to the DIV.
    centerControlDiv.appendChild(centerControl);

    map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);

    const myLatlng2 = new google.maps.LatLng(lat, lng);

    // const liveTrackMarker = new google.maps.Marker({
    //   position: myLatlng2,
    //   icon: {
    //     path: squareMarkerSVG,
    //     fillColor: "#e91e63",
    //     fillOpacity: 1,
    //     strokeColor: "#e91e63",
    //   },
    //   map: map,
    // });

    // setMarkerRef(liveTrackMarker);

    function autoRefresh(map, pathCoords) {
      var i, route;

      route = new google.maps.Polyline({
        path: [],
        geodesic: true,
        strokeColor: "#000000",
        strokeWeight: 5,
        editable: false,
        map: map,
      });

      const liveTrackMarker = new google.maps.Marker({
        position: myLatlng2,
        icon: {
          path: squareMarkerSVG,
          fillColor: "#e91e63",
          fillOpacity: 1,
          strokeColor: "#e91e63",
        },
        map: map,
      });

      for (i = 0; i < pathCoords.length; i++) {
        setTimeout(
          function (coords) {
            route.getPath().push(coords);
            if(liveTrackMarker){
              liveTrackMarker.setPosition({
                lat: coords.lat(),
                lng: coords.lng()
              })
              if( i == pathCoords.length - 1){
                setMarkerRef(liveTrackMarker)
              }
            }
           
          },
          50 * i,
          pathCoords[i]
        );
      }
    }
    // const directionsDisplay = new google.maps.DirectionsRenderer({
    //   map: map,
    //   suppressMarkers: true,
    //   // polylineOptions: {
    //   //   strokeWeight: 5,
    //   //   strokeColor: "#808080",
    //   // },
    //   preserveViewport: true,
    // });
    let bounds = new google.maps.LatLngBounds();
    // const test = "trackhistory/+18622878740/13-11-2022/"

        const today = moment().format("DD-MM-YYYY").toString();
        Firebase.database()
        .ref(`/trackhistory/${phoneNumber}/${today}/`)
        .once('value', snapshot => {
          const data = snapshot.val()
          if (data) {
            let result = []
            const keys = Object.keys(data);
            for (let index = 0; index < keys.length - 1; index++) {
              const element = data[keys[index]]["location"].split(",");
              const point = new google.maps.LatLng(parseFloat(element[0]), parseFloat(element[1]))
              result.push(point)
              bounds.extend(point)
            }
            var tourplan = new google.maps.Polyline({
              path:result,
              strokeColor:"#0000FF",
              strokeOpacity:0.6,
              strokeWeight:5
          });
          
          tourplan.setMap(map);
          map.fitBounds(bounds);
          autoRefresh(map, result)
          }
        })

        
  }, []);



  // timeline data
  // realtime activity
  React.useEffect(() => {
    if(trackViewConfig && trackViewConfig.store) {
      const onChildAdd = Firebase.database()
      .ref(`tracking/teams/teammembers/${trackViewConfig.store}/${phoneNumber}/timeline/`)
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          let result = []
          const keys = Object.keys(data);
          for (let index = keys.length - 1; index > -1; index--) {
            const element = data[keys[index]];
            result.push(
                {
                  color: colorArray[Math.floor(Math.random() * colorArray.length)],
                  icon: iconArray[Math.floor(Math.random() * iconArray.length)],
                  title: element.title,
                  dateTime: element.timeStamp,
                  description: element.body,
                  badges: ["design"],
                  img: element.img,
                  mapimage: element.mapimage
                }
            )
          }
          setUserTimelineData(result);
        }
      })
      return () => Firebase.database().ref(`/tracking/teams/teammembers/${trackViewConfig.store}/${phoneNumber}/timeline/`).off('value', onChildAdd);
    }
    
  }, [trackViewConfig])

  React.useEffect(() => {
    Firebase.database()
    .ref(`/realtimetrackingdata/${phoneNumber}/raw/`)
    .limitToLast(5)
    .on('value', snapshot => {
      const data = snapshot.val()
      if (data) {
        let result = []
        const keys = Object.keys(data);
        for (let index = keys.length - 1; index > 0; index--) {
          const element = data[keys[index]];
          result.push(
            {
              status: <DefaultCell>{element.status}</DefaultCell>,
              timestamp: <DefaultCell>{element.timestamp}</DefaultCell>,
              trackdata: (<><DefaultCell>
                <MDBadge
                  variant="contained"
                  size="xs"
                  badgeContent={`Battery:${element.battery}`}
                  color="success"
                  container
                />
                &nbsp;
                <MDBadge
                  variant="contained"
                  size="xs"
                  badgeContent={`${element.activity}`}
                  color="warning"
                  container
                />&nbsp;
                <MDBadge
                variant="contained"
                size="xs"
                badgeContent={`${element.latlng}`}
                color="dark"
                container
              /> 
              </DefaultCell></>),
            }
          )
        }
        setUserRealtimeActivtyData(result);
      }
    })
  }, [])
 
  
 

 
  const visitDetails = () => {
    return (
      <>
        <MDAlert color="success">
        <MDBox >
        <Grid container>
          <Grid item xs={12}>
          <MDBox  display="flex" alignItems="center">
            <MDAvatar alt="Avatar" size='xl' variant="rounded" src={`https://maps.googleapis.com/maps/api/staticmap?maptype=satellite&center=${trackViewConfig?.location?.latlng?.split(",")[0]},${trackViewConfig?.location?.latlng?.split(",")[1]}&zoom=18&size=550x300&maptype=roadmap&markers=color:red%7Clabel:G%7C${trackViewConfig?.location?.latlng?.split(",")[0]},${trackViewConfig?.location?.latlng?.split(",")[1]}&key=AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk`}>
            </MDAvatar>
            
              <MDBox ml={2} mt={0.5} lineHeight={1.4} >
              <MDTypography variant="body2" color="white">
                
                <MDTypography  fontWeight="medium" color="white">
                  {trackViewConfig?.location?.address} 
                </MDTypography>
              </MDTypography>
              </MDBox>
            </MDBox>
            
            </Grid>
          </Grid>
          
         </MDBox>
      
        </MDAlert>
        <br />
      </>

    )
  }

  const livetrackCard = () => {
    return (
      <>
      <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Live Location
        </MDTypography>  
        {!phoneNumber ? null : (
        <MDButton variant="outlined" color="info" size="small" onClick={()=>{window.open(`tel:${phoneNumber}`, '_self')}}>
          Call
        </MDButton>)}
      </MDBox>
      <MDBox p={2}>
        <div
          style={{
            height: `calc(60vh)`,
            borderRadius: "6px",
            overflow: "hidden",
          }}
          ref={mapRef}
        >
        </div>
      </MDBox>
      
      </Card>
      <br />
      </>
      
    )
  }
  const trackActivity = () => {
    return (
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Realtime Activity
            </MDTypography>
          </MDBox>
          <DataTable table={{
            columns: [
              { Header: "Trackdata", accessor: "trackdata", },
              { Header: "Status", accessor: "status" },
              { Header: "Timestamp", accessor: "timestamp" },
            ],
            rows: userRealtimeActivtyData
            }} entriesPerPage={false}/>
        </Card>
      </MDBox>
    )
  }

  const invalidIDCard = () => {
    return (
      <>
        <Card>   
      <MDBox p={2}>
        <Grid container>

          <Grid item xs={10}>
          
            <MDBox lineHeight={0.5}  px={1}>
             
              <MDTypography variant="button" fontWeight="bold" color={"success"}>
                No Tracking Data
                
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={2}>
          </Grid>
          
        </Grid>
      </MDBox>
      </Card>
       <br />
      </>
    )
  }
 
  const timelineCard = () => {
    return (
      <>
        <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            {userTimelineData?.length > 0 && (<TimelineList title="Timeline Activity">{
              userTimelineData?.map(
                ({ color, icon, title, dateTime, description, badges, lastItem, img, mapimage }) => (
                  <TimelineItem
                    key={title + color}
                    color={color}
                    icon={icon}
                    title={title}
                    dateTime={dateTime}
                    description={description}
                    badges={badges}
                    lastItem={lastItem}
                    img={img}
                    mapimage={mapimage}
                  />
            ))
            }</TimelineList>)}
          </Grid>
        </Grid>
        </MDBox>
      </>
    )
  }

  const renderTimelineItems = () => {
    if(userTimelineData && userTimelineData.length > 0){
      return userTimelineData.map(
        ({ color, icon, title, dateTime, description, badges, lastItem }) => (
          <TimelineItem
            key={title + color}
            color={color}
            icon={icon}
            title={title}
            dateTime={dateTime}
            description={description}
            badges={badges}
            lastItem={lastItem}
          />
    ));
    }
    
    }
  const q = ({ functions: { pxToRem } }) => ({
    width: pxToRem(46),
    height: pxToRem(46),
    minWidth: pxToRem(46),
    minHeight: pxToRem(46),
    mr: 1,
  });

  const productImages = () => {
    return (
      <>
      <Card>
        {trackingDetails?.images &&
          (
            <>
            <ImgsViewer
              imgs={trackingDetails?.images}
              isOpen={imgsViewer}
              onClose={closeImgsViewer}
              currImg={imgsViewerCurrent}
              onClickPrev={imgsViewerPrev}
              onClickNext={imgsViewerNext}
              zoomable={true}
              drag={true}
              scalable={true}
            />
            <MDBox
              component="img"
              src={trackingDetails?.images[0].src}
              alt="Product Image"
              shadow="lg"
              borderRadius="lg"
              width="100%"
              onClick={openImgsViewer}
            />
           
            </>
          )
        }
        
        
      </Card>
      <br />
      </>
   
    )
  }

  
  return (
    <DashboardLayout>
       {invalidTrackID ? invalidIDCard() : (<>
        {trackViewConfig?.location && visitDetails()}
        {livetrackCard()}
        {timelineCard()}
        {trackActivity()}
        {/* {deliveryStatus == 'completed' ? deliveryCompletedCard() : etaAndCallCards()}
        {reviewCard()}
        {trackViewConfig?.chat && discussCard()}
        {trackViewConfig?.images && productImages()} */}
       </>
        
       )}
       
      {/* <Footer /> */}
    </DashboardLayout>
  );
return (<></>);
}

export default Home;
