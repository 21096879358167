
import CssBaseline from "@mui/material/CssBaseline";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import Configurator from "examples/Configurator";
import Deliverynotes from "layouts/dashboards/deliverynotes";
// import Deals from "layouts/dashboards/deals";
import Home from "layouts/dashboards/home";
import Menu from "layouts/dashboards/menu";
// import Myorders from "layouts/dashboards/myorders";
import { useEffect } from "react";
// react-router components
import { Route, Routes, useLocation } from "react-router-dom";
import { CartProvider } from "react-use-cart";
import "./App.css";
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51K9af5BfGfOYVrvwzumqhvEMn4mkG8P45l3B2u2ertsjdJxGfPOwfX7RxTjN1RaYLP6COVMAgC3xFxdPs4ulsWd800qUHy0sL1');

export default function App() {

  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);


  return (
    <Elements stripe={stripePromise}>
      <CartProvider>
      <ThemeProvider theme={theme} stylep={{width: "100%"}}>
        <CssBaseline />
          <Configurator />
        <Routes>
          <Route exact path="/menu" element={<Menu />} key="menu" />
          <Route exact path="/deliverynotes" element={<Deliverynotes />} key="deliverynotes" />
          {/* <Route exact path="/myorders" element={<Myorders />} key="myorders" />
          <Route exact path="/deals" element={<Deals />} key="deals" /> */}
          <Route  path="*" element={<Home />} key="home" />
        </Routes>
      </ThemeProvider>
      </CartProvider>
    </Elements>
    
  );
}
