/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import Firebase from "firebase";

// react-github-btn
import GitHubButton from "react-github-btn";

// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import moment from "moment";

// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useCart } from "react-use-cart";
import OrderSummary from "layouts/ecommerce/orders/order-details/components/OrderSummary";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";


import Header from "layouts/ecommerce/orders/order-details/components/Header";
import OrderInfo from "layouts/ecommerce/orders/order-details/components/OrderInfo";
import TrackOrder from "layouts/ecommerce/orders/order-details/components/TrackOrder";
import PaymentDetails from "layouts/ecommerce/orders/order-details/components/PaymentDetails";
import BillingInformation from "layouts/ecommerce/orders/order-details/components/BillingInformation";
var axios = require('axios');


import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import orderImage from "assets/images/product-12.jpg";
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';



// const stripe = require('stripe')('sk_test_51K9af5BfGfOYVrvwXzS2eqyCAv3BatJaf51woRTwmrRlyjA183T0c1agctwFWQTVbbYVmpDB5TvbeFl6yb029dfY00AdQFS0FH');

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setWhiteSidenav,
  setMiniSidenav,
  setFixedNavbar,
  setSidenavColor,
  setDarkMode,
  setOrderId
} from "context";
import CircularProgress from '@mui/material/CircularProgress';

function Configurator() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,
    miniSidenav,
    fixedNavbar,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    customConfig,
    orderId
  } = controller;
  const [disabled, setDisabled] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [clientSecret, setClientSecret] = useState();
  const [paymentError, setPaymentError] = useState();
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [tipValue, setTipValue] = useState(10);
  const inputStyle = {
    iconColor: '#c4f0ff',
    color: '#ff0',
    fontWeight: '500',
    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    ':-webkit-autofill': {
      color: '#fce883',
    },
    '::placeholder': {
      color: '#87BBFD',
    },
}
  const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];
 
  const stripe = useStripe();
  const elements = useElements();


  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
     

    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);
  const navigate = useNavigate();

  const handleCloseConfigurator = () => {
    setPaymentError(null);
    setPaymentProcessing(true)
    setOpenConfigurator(dispatch, false);
  }
 


  const placeTheOrder = async () => {
    if(!tipValue){
      setPaymentError("Please Enter A Tip Value")
      return ;
    }
    setPaymentError(null);
    setPaymentProcessing(true)

    const amount = Math.ceil(tipValue);

    // var data = JSON.stringify({"amount":90000});

    var config = {
      method: 'post',
      url: 'https://us-central1-onroad3-e593b.cloudfunctions.net/stripeCreatePaymentIntent',
      headers: { 
        'Content-Type': 'application/json',
        'id': amount * 100
      }
    };

    const resp = await axios(config)
    if(!resp || (resp && !resp.data)){
      setPaymentError("Something Went Wrong, Please try again. You are not charged")
      setPaymentProcessing(false)
      return;
    }
    // if(!amount){
    //   setPaymentError("Something Went Wrong");
    // }
    // var requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json', 'id': amount},
    //   body: JSON.stringify({ "amount": amount})
    // };
   
    // const {client_secret} = await fetch("https://us-central1-onroad3-e593b.cloudfunctions.net/stripeCreatePaymentIntent", requestOptions)
    //   .then(response => response.json())

    // const {client_secret} = await fetch('https://us-central1-onroad3-e593b.cloudfunctions.net/createPaymentIntent', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     "amount": 39000,
    //   }),
    // })
    // .then((response) => response.json())
    // .catch((error) => {
    //   console.error("=====error===", error);
    // });

    const {paymentIntent, error} = await stripe
    .confirmCardPayment(resp.data.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'Onroad',
        },
      },
    })

    if(error){
      setPaymentProcessing(false);
      setPaymentError(error.message)
    }
    if(paymentIntent && paymentIntent.status === "succeeded"){
      Firebase.database().ref(`/test/${orderId}/tipamount`).set(tipValue)
      setPaymentProcessing(false)
      setOrderPlaced(true);
    }
  }
    
  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      {orderPlaced ? (
        <>
           <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pt={4}
            pb={0.5}
            px={35}
          >
           

          {!orderPlaced && ( <Icon
              sx={({ typography: { size }, palette: { dark, white } }) => ({
                fontSize: `${size.lg} !important`,
                color: darkMode ? white.main : dark.main,
                stroke: "currentColor",
                strokeWidth: "2px",
                cursor: "pointer",
                transform: "translateY(5px)",
              })}
              onClick={handleCloseConfigurator}
            >
              close
            </Icon>)}
           
          </MDBox>
          
            <MDBox
             justifyContent="center" 
             alignItems="center"
             style={{position: "fixed", transform: "translate(50%, 100%)" }}
            >
              <MDTypography variant="h5">Paid</MDTypography>
              <br />
              <MDTypography variant="body2" color="text">
                Thank You Very Much
              </MDTypography>
              <br />
              <MDBox display="flex" alignItems="center" justifyContent="center">
                <MDButton variant="gradient" color="dark" size="large" onClick={() => {
                  handleCloseConfigurator();
                  setOrderPlaced(false);
                  setPaymentError(false)
                  setPaymentProcessing(false)
                  return navigate(-1);
                  }}>
                  Go Back
                </MDButton>
              </MDBox>
              
            </MDBox>
            
        </>
      ) :
      (
        <>
           <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
            pt={4}
            pb={0.5}
            px={3}
          >
            <MDBox>
              <MDTypography variant="h5">Thank You</MDTypography>
              <MDTypography variant="body2" color="text">
                They work really hard to get this to you
              </MDTypography>
            </MDBox>

            <Icon
              sx={({ typography: { size }, palette: { dark, white } }) => ({
                fontSize: `${size.lg} !important`,
                color: darkMode ? white.main : dark.main,
                stroke: "currentColor",
                strokeWidth: "2px",
                cursor: "pointer",
                transform: "translateY(5px)",
              })}
              onClick={handleCloseConfigurator}
            >
              close
            </Icon>
          </MDBox>

          <Divider />

          <MDBox pt={0.5} pb={3} >
            
              <MDBox mb={3}>
              <MDBox
                component="img"
                id={0}
                src={"https://firebasestorage.googleapis.com/v0/b/onroad3-e593b.appspot.com/o/deliverythankyounote.png?alt=media&token=68e3453a-8191-4254-830e-37cd226d9c6a"}
                alt="small image 1"
                borderRadius="lg"
                shadow="md"
                width="100%"
                
                minHeight="5rem"
                sx={{ cursor: "pointer", objectFit: "cover" }}
              />
              </MDBox>
              
              <br />
              {true && (
                <MDBox mb={3}>
                   {/* <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                /> */}
                  <MDInput
                    inputProps={{ type: "number", min: 1, max: 1000 }}
                    placeholder="Tip amount"
                    value={tipValue}
                    fullWidth
                    onChange={({ currentTarget }) => {
                      setPaymentError(null)
                      setTipValue(currentTarget.value);
                    }}
                />
                </MDBox>)
              }
              <br />
              <CardElement  options={{
                  // style: {
                  //   base: inputStyle,
                  // },
                }}
                />
                <br />

                <span style={{fontSize: "8px", textAlign: "center", paddingLeft: "20px"}}>Powered By Stripe SSL Protected, PCI Compliant and Encrypted </span>
              <br />
              {(<></>)}
              
              {paymentError &&
                (<>
                   <MDBox mt={3}  alignItems="center" justifyContent="center">
                    <MDTypography variant="danger"  color="error" fontWeight="medium">
                     {paymentError}
                    </MDTypography>
                  </MDBox>
                    
                </>)
              }
              <br />
              {paymentProcessing && (
                <MDBox mt={3} style={{textAlign: "center"}}>
                  <CircularProgress />
                </MDBox>
              )}
              <MDBox mt={3} style={{textAlign: "center"}}>
                <MDButton variant="gradient" color="dark" size="large" onClick={() => {return placeTheOrder()}}>
                  Tip
                </MDButton>
              </MDBox>
              <br />
              <br />
              <br />
              <br />
          </MDBox>
        </>
      )}
     

    </ConfiguratorRoot>
  );
}

export default Configurator;
