/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
// Material Dashboard 2 PRO React components

// custom styles for the DefaultItem
import BookingCard from "examples/Cards/BookingCard";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import Grid from "@mui/material/Grid";
import StarIcon from '@mui/icons-material/Star';
// custom styles for the DefaultItem
import defaultItemIconBox from "examples/Items/DefaultItem/styles";
import ReactPlayer from 'react-player'
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'
import React, { useRef } from 'react'

const DefaultItem = forwardRef(({ color, icon, title, description, onClick, ...rest }, ref) => 
{
  const player = useRef(null);
  const handleClickFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.request(player.current.wrapper);
    }
  };

return (
  <Grid container>
          <Grid item xs={11}>
          <MDBox {...rest} ref={ref} display="flex" alignItems="center">
          {/* <ReactPlayer
              url='https://www.youtube.com/watch?v=wcJfWoLwe6g'
              className='react-player'
              width='1px'
              height='1px'
              ref={player}
              config={{
                youtube: {
                  playerVars: { showinfo: 1 }
                }
              }}
              onPlay={handleClickFullscreen}
            /> */}
            <MDAvatar alt="Avatar" size='xl' variant="rounded" src="https://bit.ly/34BY10g">
           
            
            </MDAvatar>
            
              <MDBox ml={2} mt={0.5} lineHeight={1.4} onClick={onClick}>
                <MDTypography display="block" variant="button" fontWeight="medium"
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical"
                  }}>
                  {title}{title}{title}{title}
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text"
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical"
                  }}>
                  {description}{description}{description}
                </MDTypography>
          
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  {/* <Icon>star</Icon><Icon>star</Icon><Icon>star</Icon><Icon>star</Icon> */}
                  <b>244</b>
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDTypography variant="body2" fontWeight="regular" color="text"  style={{paddingLeft: "20px"}}>
              <MDButton variant="text" color="info" iconOnly size="small" onClick={() => {
                // console.log("=======player===", player.current)
                // if (screenfull.isEnabled) {
                //   screenfull.request(player.current.wrapper);
                // }
                // player.current.seekTo(2);
                window.open("https://www.youtube.com/watch?v=wcJfWoLwe6g", "_blank") 
              }}>
                <Icon style={{fontSize: "30px"}}>play_arrow</Icon> Video
              </MDButton>
            </MDTypography>

            </Grid>

          <Grid item xs={1}>
          {/* <MDButton variant="outlined" color="info" iconOnly size="small">
            <Icon>remove</Icon>
          </MDButton>
          {"2"} */}
          {/* <MDButton variant="outlined" color="info" iconOnly size="small">
            <Icon>add</Icon>
          </MDButton> */}
             <MDBox  textAlign="center" >
             <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" style={{height: "15px", padding: 0}}>
                <MDButton variant="text" color="info" iconOnly size="small" style={{fontSize: "1em!important", fontWeight: "20px"}}>
                  <Icon>remove</Icon>
                </MDButton> 
              </MDTypography>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" style={{height: "15px", padding: 0}}>
                1
              </MDTypography>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" style={{height: "15px", padding: 0}}>
                <MDButton variant="text" color="info" iconOnly size="small">
                  <div style={{fontSize: "1em!important"}}>
                    <Icon>add</Icon>
                  </div>
                </MDButton> 
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
)
}

);

// Setting default values for the props of DefaultItem
DefaultItem.defaultProps = {
  color: "info",
};

// Typechecking props for the DefaultItem
DefaultItem.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default DefaultItem;
