
// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import MDBadge from "components/MDBadge";
// Material Dashboard 2 PRO React context
import {
  setMiniSidenav,
  setOpenConfigurator, setTransparentNavbar, useMaterialUIController
} from "context";
import NotificationItem from "examples/Items/NotificationItem";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer
} from "examples/Navbars/DashboardNavbar/styles";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// react-router components
import { useLocation } from "react-router-dom";


function DashboardNavbar({ absolute, light, isMini, onFilter, categories }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleCloseMenu = () => setOpenMenu(false);
  const onClick = (val) => {
    setSelectedFilter(val)
    onFilter(val)
  }
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
    <Toolbar sx={(theme) => navbarContainer(theme)} style={{ overflow: "scroll"}}>
      <Stack direction="row">
        {/* <MDBadge badgeContent="Checkout" color="light"  size="lg" container/> */}

        <span style={{width: "80px"}}></span>
        {categories.map((val) => {
          return (
            <>
            <MDBadge badgeContent={val} color={selectedFilter === val ? "dark" : "light"}  size="lg" container onClick={() => {
              onClick(val)
            }}/>&nbsp;&nbsp;
            </>
          )
        })}
      </Stack>
    </Toolbar>
  </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  onFilter: PropTypes.func,
  categories: PropTypes.array
};

export default DashboardNavbar;
