
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from '@mui/material/Modal';
// Material Dashboard 2 PRO React contexts
import image5 from "assets/images/products/product-details-5.jpg";
// Material Dashboard 2 PRO React components
import MDBadgeDot from "components/MDBadgeDot";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { setOpenConfigurator, useMaterialUIController } from "context";
// Material Dashboard 2 PRO React examples
import DefaultItem from "examples/Items/DefaultItem";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import MDAvatar from "components/MDAvatar";
import MDPagination from "components/MDPagination";
import { useLocation } from "react-router-dom";

function Menu(props) {
  const location = useLocation()
  const { list, config } = location.state
  
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = React.useState(false);
  const { sales, tasks } = reportsLineChartData;
  const [open, setOpen] = React.useState(false);
  const [currentProduct, setCurrentProduct] = React.useState();
  const [filterCategory, setFilterCategory] = React.useState("All");
  const [allCategories, setAllCategories] = React.useState([]);
  const [groupByList, setGroupByList] = React.useState();

 
  const groupByKey = (array, key) => {
    return array
      .reduce((hash, obj) => {
        if(obj[key] === undefined) return Object.assign(hash, { ["Misc"]:( hash["Misc"] || [] ).concat(obj)})
        return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
      }, {})
 }
   
  React.useEffect(() => {

    if(!list){
      return
    }
    if(list && list.length > 0){
      let categories = ["All"]
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        categories.push(element.category)
      }
      categories = categories.filter((x, i, a) => categories.indexOf(x) === i)
      if(categories.length > 0){
        setAllCategories(categories)
        setGroupByList(groupByKey(list, "category"))
      }
    }
    console.log("=========", groupByKey(list, "category"))
   
  }, [list])

  const colors = ["info", "primary", "dark", "secondary"]

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    items,
    updateItemQuantity,
    totalItems,
    removeItem,
    emptyCart,
    addItem,
    metadata,
    getItem
  } = useCart();
  const navigate = useNavigate();

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const uiItem = (product) => {
    return (
      <Grid container>
          <Grid item xs={11}>
          <MDBox  display="flex" alignItems="center">
            <MDAvatar alt="Avatar" size='xl' variant="rounded" src={product.image}>
            </MDAvatar>
            
              <MDBox ml={2} mt={0.5} lineHeight={1.4} onClick={() => {
                setCurrentProduct(product)
                handleOpen()
              }}>
                <MDTypography display="block" variant="button" fontWeight="medium"
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical"
                  }}>
                  {product.name}
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="text"
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical"
                  }}>
                  {product.description}
                </MDTypography>
          
                <MDTypography variant="caption" fontWeight="regular" color="text">
                  {/* <Icon>star</Icon><Icon>star</Icon><Icon>star</Icon><Icon>star</Icon> */}
                  <b>{product.price}</b>
                </MDTypography>
              </MDBox>
            </MDBox>
           {product?.video && (<MDTypography variant="body2" fontWeight="regular" color="text"  style={{paddingLeft: "20px"}}>
              <MDButton variant="text" color="info" iconOnly size="small" onClick={() => {
                // console.log("=======player===", player.current)
                // if (screenfull.isEnabled) {
                //   screenfull.request(player.current.wrapper);
                // }
                // player.current.seekTo(2);
                window.open(product?.video, "_blank") 
              }}>
                <Icon style={{fontSize: "30px"}}>play_arrow</Icon> Video
              </MDButton>
            </MDTypography>)}

            </Grid>

          <Grid item xs={1}>
            {getItem(product?.id)?.quantity > 0 ?
              (
                <MDBox  textAlign="center" >
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" style={{height: "15px", padding: 0}}>
                      <MDButton variant="text" color="info" iconOnly size="small" style={{fontSize: "1em!important", fontWeight: "20px"}} onClick={() => { addToCart(product)}}>
                        <Icon>add</Icon>
                      </MDButton> 
                    </MDTypography>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" style={{height: "15px", padding: 0}}>
                      {getItem(product?.id)?.quantity}
                    </MDTypography>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" style={{height: "15px", padding: 0}}>
                      <MDButton variant="text" color="info" iconOnly size="small" onClick={() => { removeFromCart(product)}}>
                        <div style={{fontSize: "1em!important"}}>
                          <Icon>remove</Icon>
                        </div>
                      </MDButton> 
                    </MDTypography>
                  </MDBox>
              ) : 
              (
                <MDButton variant="outlined" color="info" iconOnly size="small" onClick={() => {
                  addToCartAndChangeButtons(product)
                }}>
                  <Icon>add</Icon>
                </MDButton>
              )
            }
            
          </Grid>
          </Grid>
    )
  }

  const addToCart = (product) => {
    const item = getItem(product.id);

    updateItemQuantity(item.id, item.quantity + 1)
  }
  const removeFromCart = (product) => {
    const item = getItem(product.id);
    if(item.quantity == 1 ){
      removeItem(item.id)
    }else{
      updateItemQuantity(item.id, item.quantity - 1)
    }
  }
  const addToCartAndChangeButtons = (product) => {
    addItem(product, 1);
  }
  const filterCriteria = (val) => {
    setFilterCategory(val)
  }
  return (
      <DashboardLayout>
        <Modal
          open={open}
          onClose={handleClose}
          style={{
            minHeight: '80vh',
            maxHeight: '80vh',
          }}
        >
          <Card  style={{
            top: '20%',
            padding: "15px"
          }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
              <MDTypography variant="h6">{currentProduct?.name} </MDTypography>
              <MDButton variant="text" color="error" size="small" onClick={handleClose}>
                <Icon>close</Icon>
              </MDButton>
            </MDBox>
            <MDBox mt={3}>
              <Grid container alignItems="center">
                <Grid item xs={7} style={{
                      paddingLeft: "20px"
                    }}>
                  <MDBox
                    component="img"
                    id={0}
                    src={currentProduct?.image}
                    alt="small image 1"
                    borderRadius="lg"
                    shadow="md"
                    width="100%"
                  />
                </Grid>
                <Grid item xs={5}>
                  <MDBox pr={1}>
                    {currentProduct?.health?.split(",").map(i => {
                      return (
                      <MDBox mb={1}>
                        <MDBadgeDot color={colors[Math.floor(Math.random() * 4)]} size="sm" badgeContent={i} />
                      </MDBox>
                      )
                    })}
                    <MDBox mb={1}>
                      <MDBadgeDot color={"info"} size="sm" badgeContent={`${currentProduct?.calories ? currentProduct?.calories : ""} calories`} />
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox  
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              mt="auto"
            >
              <MDBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>
                <MDTypography variant="button" color="text" fontWeight="light">
                  {currentProduct?.description}{" "}
                  <strong>{currentProduct?.calories}</strong>
                </MDTypography>
              </MDBox>
              <MDBox width={{ xs: "100%", sm: "40%" }} textAlign="right" mt={{ xs: 2, sm: "auto" }} style={{display: "inherit", textAlign: "right", justifyContent: "right", alignItems: "end" }}>
                <MDBox textAlign="right" style={{paddingLeft: "15px"}}>
                  {getItem(currentProduct?.id)?.quantity > 0 ?  
                  (<MDPagination variant="contained">
                    <MDPagination item onClick={() => { removeFromCart(currentProduct)}}>
                      <Icon>remove</Icon>
                    </MDPagination>
                    <MDPagination item active>{getItem(currentProduct?.id)?.quantity}</MDPagination>
                    <MDPagination item onClick={() => { addToCart(currentProduct)}}>
                      <Icon>add</Icon>
                    </MDPagination>
                  </MDPagination>)
                  :
                  (<MDButton color={"light"} onClick={() => {return addToCartAndChangeButtons(currentProduct)}}>
                    Add To Cart
                  </MDButton>)
                  }
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Modal>
        <DashboardNavbar  onFilter={filterCriteria} categories={allCategories}/>
        <MDBox
          alignItems="center"
          height="3.25rem"     
          position="fixed"
          left="5px"
          bottom="1rem"
          zIndex={99}
          color="dark"
          sx={{ cursor: "pointer" }}
        
          style={{
            paddingRight: "15px",
            paddingLeft: "20px",
            justifyContent: "space-between",
            width:"99%",
            display:"flex",
            overflow: "auto",
          whiteSpace: "nowrap"
          }}
        >
          <MDButton variant="gradient" color="info" iconOnly style={{fontSize: "1.5rem!important"}}
          onClick={() => {
            navigate(-1)
          }}
          >
            <Icon size="lg">home</Icon>
          </MDButton>
          
          <MDButton variant="gradient" color="info" size="large"  onClick={handleConfiguratorOpen} disabled={isEmpty}>Checkout</MDButton>
        </MDBox>
        <Card sx={{ height: "100%" }}>
          {filterCategory == 'All' && (<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
            <MDTypography variant="h6" fontWeight="medium">
              Menu
            </MDTypography>
          </MDBox>)}
          <MDBox p={2}>
            {filterCategory == 'All' ?
              (<>
                {
                  groupByList && Object.keys(groupByList).map((cat) => {
                    return (<>
                      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                        <MDTypography variant="h6" fontWeight="medium" style={{textTransform: "capitalize"}}>
                          {cat}
                        </MDTypography>
                      </MDBox>
                        {groupByList[cat].map((product, key) => {
                          return (
                            <MDBox mt={2.5}>
                              {uiItem(product)}
                            </MDBox>
                          )
                        })}
                    </>)
                  })
                }
               </>) :
              (<>
                <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h6" fontWeight="medium" style={{textTransform: "capitalize"}}>
                    {filterCategory}
                  </MDTypography>
                </MDBox>
                {groupByList[filterCategory].map((product, key) => {
                  return (
                    <MDBox mt={2.5}>
                      {uiItem(product)}
                    </MDBox>
                  )
                })}
              </>)
            }
            {/* {filterCategory == 'All' ? 
              () :
              (
                {groupByList[filterCategory].map((product, key) => {
                  return (
                    <MDBox mt={2.5}>
                      {uiItem(product)}
                    </MDBox>
                  )
                })}
              })
            } */}
           
          {/* {list && list.filter(item => {
            if(filterCategory == 'All'){
              return true
            }else{
              return item.category === filterCategory
            }
          }).map((product, key) => {
              return (
                <MDBox mt={2.5}>
                  {uiItem(product)}
                </MDBox>
              )
            })
            } */}
          </MDBox>
        </Card>
        <br />
      </DashboardLayout>
  );
}

export default Menu;
